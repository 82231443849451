import { NextApiRequest } from 'next/types';
import packageInfo from 'package.json';

type RedirectInput = {
    gameId: string;
    sessionId?: string;
    baseUnit?: string;
    currency?: string;
    token?: string;
    [key: string]: any;
};

const getRedirectURL = (input: RedirectInput, path?: string) => {
    const { gameId } = input;
    const _path = path || `/games/${gameId}`;
    const url = new URL(process.env.NEXT_PUBLIC_URL + _path);

    for (const [key, value] of Object.entries(input)) {
        url.searchParams.set(key, value as string);
    }

    return url.href;
};

const hasMissingVariables = (
    variableList: string[],
    query: NextApiRequest['query'],
) => {
    const missingVariables: string[] = [];
    variableList.forEach((variable) => {
        if (!query[variable]) {
            missingVariables.push(variable);
        }
    });

    const result = missingVariables.length > 0 ? missingVariables : null;

    if (result) {
        console.error('Missing variables', missingVariables);
    }

    return result;
};

const getGameVersion = () => packageInfo.version;

export { getGameVersion, getRedirectURL, hasMissingVariables };
