import type { GetStaticProps } from 'next';
import React from 'react';

import { getGameVersion } from '@/helpers/launch';

const Casino = ({ buildDate }: { buildDate: string }) => (
    <div className='container py-4 text-white bg-black'>
        <div className='date mb-2'>
            Build Date: <b>{buildDate}</b>
        </div>
        <div className='date mb-2'>
            Game Kit Version: <b>v{getGameVersion()}</b>
        </div>
    </div>
);

export const getStaticProps: GetStaticProps = async () => ({
    props: {
        buildDate: new Date().toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        }),
    },
});

export default Casino;
